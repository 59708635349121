import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Gallery from "../components/gallery"
import { home } from "../utils/image"
import t from "../utils/translate"

export const query = graphql`
  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...CommonPage
      gallery {
        asset {
          _id
        }
        imageMobile {
          asset {
            _id
          }
        }
        nameandsurname
        personalrole
      }
    }
  }
`

const HomepageTemplate = ({
  data: { sanityPage: page },
  pageContext: { locale, strings },
}) => {
  const items = page.gallery.map(img => ({
    srcSet: `
      ${home(img.imageMobile || img, 400)} 400w,
      ${home(img.imageMobile || img, 632)} 632w,
      ${home(img, 807)} 807w,
      ${home(img, 1022)} 1022w,
      ${home(img, 1600)} 1600w
    `,
    srcSetMobile: `
      ${home(img.imageMobile || img, 400)} 400w,
      ${home(img.imageMobile || img, 632)} 632w,
    `,
    src: home(img, 1600),
    alt: t(page.title, locale),
  }))
  return (
    <Layout locale={locale} mobileHeader={false}>
      <Seo locale={locale} seofield={page.seo}></Seo>
      <Gallery step={2500} items={items}></Gallery>
      <div style={{ display: "none" }}>v0.1</div>
    </Layout>
  )
}

export default HomepageTemplate

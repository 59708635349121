import React, { useEffect, useState } from "react"

import styles from "./gallery.module.css"

const Gallery = ({ step = 2500, items }) => {
  const [visible, setVisible] = useState(0)
  useEffect(() => {
    const i = setInterval(() => {
      setVisible(prev => (prev < items.length - 1 ? prev + 1 : 0))
    }, 2500)
    return () => clearInterval(i)
  }, [items])
  return (
    <div className={styles.gallery}>
      {items && items.map(({ src, srcSet, srcSetMobile, alt }, index) => (
        <picture key={src}>
          <source media="(orientation: portrait)" srcSet={srcSetMobile} />
          <source media="(orientation: landscape)" srcSet={srcSet} />
          <img
            srcSet={srcSet}
            src={src}
            alt={alt}
            className={index === visible ? styles.visible : styles.img}
          />
        </picture>
      ))}
    </div>
  )
}

export default Gallery
